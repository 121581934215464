import React, { FunctionComponent } from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import { CardsProductItemInterface } from "../types";
import "./styles.scss";
import { IMAGE_SIZES } from "../../../constants";
import { Link } from "../../Link";

const CardsProductItem: FunctionComponent<CardsProductItemInterface> = ({ card }) => {
  const {
    _rawFirstImage,
    _rawSecondImage,
    firstProductBin,
    secondProductBin,
    isfirstProductBinEnable,
    isSecondProductBinEnable
  } = card;

  return (
    <div className={`c-card-product`}>
      <div className="c-card-product__item">
        <div className="c-card-product__image">
          <SanityImage
            {..._rawFirstImage}
            sizes={IMAGE_SIZES.HALF}
            width={172}
            height={250}
            htmlWidth={172}
            htmlHeight={250}
            className={`c-card-product__image-img img-fluid`}
          />
          <p className="c-card-product__benefit">{firstProductBin[0].benefitTagLine}</p>
          <p className="c-card-product__name">{firstProductBin[0].name}</p>
          <p className="c-card-product__price">$ {firstProductBin[0].price}</p>
          {isfirstProductBinEnable && (
            <Link
              className="c-card-product__shop button-septenary button-size-sm button-form-br-50 product-card__add-to-cart"
              to={`${firstProductBin[0].slug.current}`}
            >
              Shop Product
            </Link>
          )}
        </div>
      </div>
      {secondProductBin && (
        <div className="c-card-product__item">
          <div className="c-card-product__image">
            <SanityImage
              {..._rawSecondImage}
              sizes={IMAGE_SIZES.HALF}
              width={172}
              height={250}
              htmlWidth={172}
              htmlHeight={250}
              className={`c-card-product__image-img img-fluid`}
            />
            <p className="c-card-product__benefit">{secondProductBin[0].benefitTagLine}</p>
            <p className="c-card-product__name">{secondProductBin[0].name}</p>
            <p className="c-card-product__price">$ {secondProductBin[0].price}</p>
            {isSecondProductBinEnable && (
              <Link
                className="c-card-product__shop button-septenary button-size-sm button-form-br-50 product-card__add-to-cart"
                to={`${secondProductBin[0].slug.current}`}
              >
                Shop Product
              </Link>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CardsProductItem;
