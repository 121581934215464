import React, { FunctionComponent, useState } from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import CtaBlock from "../../CtaBlock";
import { CardsAccordionItemInterface } from "../types";
import RichText from "../../RichText";
import "./styles.scss";
import { event66 } from "../../../analytics/event66";
import { IMAGE_SIZES } from "../../../constants";

const CardsAccordionItem: FunctionComponent<CardsAccordionItemInterface> = ({
  isOpen = false,
  card,
  isOpenManually
}) => {
  const { bgColor, ctaLink, title, _rawDescription, _rawImage, _key, subtitle, badge, showSubHeading } = card;
  const [isCardOpen, setIsCardOpen] = useState(isOpenManually ? isOpenManually : subtitle ? false : isOpen);
  const currentAccordionStateClass = !isCardOpen ? "is-hidden" : "";

  const handleCardAnimation = function () {
    setIsCardOpen(!isCardOpen);
    event66(title, true);
  };

  return (
    <div className={`c-card-accordion`} style={{ backgroundColor: `${bgColor?.value}` }}>
      <button
        aria-controls={`c-card-accordion__copy${_key}`}
        aria-expanded={isCardOpen}
        className={`c-card-accordion__action-btn ${!isCardOpen ? "card--closed" : ""}`}
        id={`c-card-accordion__action-btn${_key}`}
        onClick={handleCardAnimation}
        type="button"
      >
        <span className={isCardOpen ? "isOpen" : ""}>&darr;</span>
      </button>
      <div className={showSubHeading ? "c-card-accordion__image" : "c-card-accordion__imageWithoutSubHeadingOrBadge"}>
        <SanityImage
          {..._rawImage}
          sizes={IMAGE_SIZES.HALF}
          width={isOpenManually ? 400 : 582}
          height={isOpenManually ? 385 : 489}
          htmlWidth={582}
          htmlHeight={489}
          className={`c-card-accordion__image-img img-fluid`}
        />
      </div>
      <div className="c-card-accordion__copy">
        {title && <h2 className={subtitle ? "c-card-accordion__titleV2" : "c-card-accordion__title"}>{title}</h2>}
        {subtitle && showSubHeading && <h3 className="c-card-accordion__subtitle">{subtitle}</h3>}
        <div
          className={currentAccordionStateClass}
          id={`c-card-accordion__copy${_key}`}
          role="region"
          aria-labelledby={`c-card-accordion__action-btn${_key}`}
        >
          {badge && <span className="c-card-accordion__badge">{badge}</span>}

          <div className="c-card-accordion__desc">
            <RichText data={_rawDescription} />
          </div>

          {ctaLink && ctaLink?.ctaLabel && (
            <div className={!subtitle ? "c-card-accordion__cta" : "c-card-accordion__ctaSecundary"}>
              <CtaBlock ctaBlock={ctaLink} ctaLabel={ctaLink?.ctaLabel} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardsAccordionItem;
