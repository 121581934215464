import React, { FunctionComponent, useState } from "react";
import CardsAccordionItem from "./CardsAccordionItem";
import CardsProductItem from "./CardsProductItem";
import {
  CardsAccordionInterface,
  CardsAccordionDataInterface,
  CardsProductInterface,
  CardsProductDataInterface
} from "./types/index";
import "./styles.scss";

// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import { RichTextSerializers } from "../../utils/richTextSerializers";
import Section from "../Section";

const CardsAccordion: FunctionComponent<CardsAccordionInterface | CardsProductInterface> = props => {
  const { cards, title, _rawDescription, theme, isOpen } = props;

  const isFirstItem = (index: number) => {
    return index === 0 ? true : false;
  };

  return (
    <Section sectionClassName="c-section--cardsAccordion" theme={theme?.bgColor?.title}>
      <div className="grid-row">
        {(title || _rawDescription) && (
          <div className="grid-col">
            <header>
              {title && <h2 className="c-section__title">{title}</h2>}
              {_rawDescription && (
                <div className="c-section__desc">
                  <BlockContent blocks={_rawDescription} serializers={RichTextSerializers()} />
                </div>
              )}
            </header>
          </div>
        )}
      </div>
      <div className="grid-row">
        {cards &&
          cards.map((card: CardsAccordionDataInterface, index: number) => {
            return (
              <div className="grid-col grid-col-md-6" key={card?._key}>
                {cards[index]._type === "cardsAccordionItem" && (
                  <CardsAccordionItem isOpenManually={isOpen} isOpen={isFirstItem(index)} card={card} />
                )}
                {cards[index]._type === "cardsProductItem" && <CardsProductItem card={card} />}
              </div>
            );
          })}
      </div>
    </Section>
  );
};

export default CardsAccordion;
